@import './themes/default';
@import './mixins';
@import './anim';

*,
*:before,
*:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: @fill-body;
  font-size: @font-size-base;
}

*[contenteditable] {
  -webkit-user-select: auto !important;
}

*:focus {
  outline: none;
}

a {
  background: transparent;
  text-decoration: none;
  outline: none;
}
